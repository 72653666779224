import { message } from 'antd';
import { take, call, put, fork } from 'redux-saga/effects';
import { contactUs as contactUsActions, checkEmail as checkEmailActions } from './actions';
import { checkValidEmail, contactUs } from 'utils/apollo';
import ActionTypes from './constants';

function* contactUsFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CONTACT_US_SUBMIT);
    payload.lang = localStorage.getItem('lang') || 'vi';
    yield put(contactUsActions.request());
    const response = yield call(contactUs, { input: payload });
    if (!response.errors) {
      yield put(contactUsActions.success());
    } else {
      yield put(contactUsActions.failure(response.errors));
      response.errors.map((e: any) => message.error(e.message));
    }
  }
}

function* checkEmailFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CHECK_EMAIL_SUBMIT);
    yield put(checkEmailActions.request());
    const response = yield call(checkValidEmail, { email: payload });
    if (!response.errors) {
      yield put(checkEmailActions.success(response));
    } else {
      yield put(checkEmailActions.failure(response.errors[0].message));
    }
  }
}

export default function* contactUsPageSaga() {
  yield fork(contactUsFlow);
  yield fork(checkEmailFlow);
}
