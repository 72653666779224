import React, { useMemo, FC, useEffect, useRef, useState } from 'react';
import topBg from './top-bg.png';
import media from 'utils/mediaStyle';
import styled from 'styled-components';
import PageHeader from '../PageHeader/PageHeader';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/ContactUsPage/messages';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Input as AInput, Form } from 'antd';
import { ContactUsForm as IContactUsForm } from 'containers/ContactUsPage/types';
import utilsMessages from 'utils/messages';
import TrackingInput from 'components/TrackingComponents/TrackingInput';
import TrackingButton from 'components/TrackingComponents/Button';
import * as Sentry from '@sentry/browser';
import { debounce } from 'lodash';
import { Link, RouteComponentProps } from 'react-router-dom';
import { checkEmailRegex } from 'utils/utilities';
import Select from 'components/Select/Select';
import shuffle from 'lodash/shuffle';
import { CustomerAcquisitionSource } from 'types/schema';
import translations from 'translations';

interface IOwnProps {
  isSuccess: any;
  isCheckingEmail: boolean;
  validationEmailMessage: string;
  onSubmit: (values: IContactUsForm) => void;
  onCheckEmail: (data: string) => void;
}

type Props = IOwnProps & RouteComponentProps;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;

const AntdInput = styled(AInput)<any>``;

const PrefixIcon = styled(LegacyIcon)`
  color: rgba(0, 0, 0, 0.25);
`;

const ReadMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const BusinessNoticeParahraph = styled.div`
  color: #9e9e9e;
  margin-bottom: 24px;
`;

const LandingPageAnchor = styled.a`
  color: #42a5f5;
`;

const ReadMoreAnchor = styled.a`
  color: #2e904e;
  font-weight: bold;
  border: 1px solid #2e904e;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  &:hover {
    color: white;
    background-color: #2e904e;
  }
`;

const acquisitionSource = [
  {
    label: 'Google',
    value: CustomerAcquisitionSource.Google,
  },
  {
    label: 'Facebook',
    value: CustomerAcquisitionSource.Facebook,
  },
  {
    label: 'Youtube',
    value: CustomerAcquisitionSource.Youtube,
  },
  {
    label: 'TikTok',
    value: CustomerAcquisitionSource.Tiktok,
  },
  {
    label: 'Instagram',
    value: CustomerAcquisitionSource.Instagram,
  },
  {
    label: translations(utilsMessages.otherWebsite),
    value: CustomerAcquisitionSource.OtherWebsite,
  },
  {
    label: translations(utilsMessages.kamereoSalesman),
    value: CustomerAcquisitionSource.KamereoSalesman,
  },
  {
    label: translations(utilsMessages.fromAcquaintance),
    value: CustomerAcquisitionSource.FriendsColleaguesAcquaintance,
  },
  {
    label: translations(utilsMessages.OOHAds),
    value: CustomerAcquisitionSource.OohAds,
  },
  {
    label: translations(utilsMessages.offlineEvent),
    value: CustomerAcquisitionSource.OfflineEvents,
  },
  {
    label: translations(utilsMessages.other),
    value: CustomerAcquisitionSource.Other,
  },
];
const ContactUsForm: FC<Props> = (props) => {
  const [form] = Form.useForm();
  const { isCheckingEmail, validationEmailMessage, isSuccess, onSubmit, onCheckEmail } = props;
  const emailValidationCallback = useRef<any>(null);
  const otherAcquisitionSourceInputRef = useRef<any>(null);
  const currentLang = window.localStorage.getItem('lang') || 'vi';
  const [isShowAcquisitionText, setIsShowAcquisitionText] = useState(false);
  const acquisitionSourceData = useMemo(() => {
    const dataShuffle = acquisitionSource.slice(0, acquisitionSource.length - 1);
    const acquisitionSourceList = shuffle(dataShuffle);
    acquisitionSourceList.push(acquisitionSource[acquisitionSource.length - 1]);
    return acquisitionSourceList;
  }, []);

  const checkExistEmail = useMemo(
    () =>
      debounce((email) => {
        onCheckEmail(email);
      }, 200),
    [],
  );

  const checkEmail = () => () => {
    const email = form.getFieldValue('email');
    return Promise.resolve().then(() => {
      if (!checkEmailRegex.test(String(email).toLowerCase())) {
        return;
      }
      return new Promise((resolve, reject) => {
        emailValidationCallback.current = {
          resolve: resolve,
          reject: reject,
        };
        checkExistEmail(email);
      });
    });
  };
  const onAcquisitionSourceChange = (value) => {
    if (value === CustomerAcquisitionSource.Other) {
      setIsShowAcquisitionText(true);
      return;
    }
    setIsShowAcquisitionText(false);
  };
  useEffect(() => {
    if (!isCheckingEmail && emailValidationCallback.current) {
      const { resolve, reject } = emailValidationCallback.current;
      if (validationEmailMessage) {
        reject(validationEmailMessage);
      }
      resolve();
      emailValidationCallback.current = null;
    }
    if (isSuccess) {
      form.resetFields();
      props.history.push('/contact-success');
    }
  }, [isCheckingEmail, isSuccess]);

  useEffect(() => {
    if (isShowAcquisitionText && otherAcquisitionSourceInputRef.current) {
      otherAcquisitionSourceInputRef.current.input.focus();
    }
  }, [isShowAcquisitionText]);
  if (isSuccess) {
    return (
      <Container>
        <PageHeader size="large">
          <FormattedMessage {...messages.header} />
        </PageHeader>
        <FormattedMessage {...messages.sendSuccessfully} />
        <ReadMore>
          <ReadMoreAnchor
            target="_Blank"
            href={`https://kamereo.vn/${currentLang === 'vi' ? 've-kamereo' : 'about-kamereo'}`}
          >
            <FormattedMessage {...utilsMessages.readMoreAboutUs} />
          </ReadMoreAnchor>
        </ReadMore>
      </Container>
    );
  }
  return (
    <Container>
      <PageHeader size="large">
        <FormattedMessage {...messages.header} />
      </PageHeader>
      <BusinessNoticeParahraph>
        <FormattedMessage {...messages.businessNotice} />{' '}
        <LandingPageAnchor
          target="_Blank"
          rel="noopener noreferrer"
          href={`https://kamereo.vn/${currentLang === 'vi' ? 've-kamereo' : 'about-kamereo'}`}
        >
          <FormattedMessage {...messages.findOutAboutTheService} />
        </LandingPageAnchor>
      </BusinessNoticeParahraph>
      <Form
        form={form}
        onFinish={(values) => onSubmit(values)}
        onFinishFailed={(error) => Sentry.captureException(error, { level: Sentry.Severity.Warning })}
        className="login-form"
        size="large"
        layout="vertical"
      >
        <FormattedMessage {...utilsMessages.fullName}>
          {(userName: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: userName }}>
              {(fieldRequired: string) => (
                <Form.Item
                  name="userName"
                  colon={false}
                  required={false}
                  label={userName}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                  ]}
                >
                  <TrackingInput
                    prefix={<PrefixIcon type="user" />}
                    placeholder={userName}
                    options={{
                      action: 'Enter Username',
                      category: 'Contact Us Form | Input',
                    }}
                  >
                    <AntdInput />
                  </TrackingInput>
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.businessName}>
          {(companyName: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
              {(fieldRequired: string) => (
                <Form.Item
                  name="companyName"
                  colon={false}
                  required={false}
                  label={companyName}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                  ]}
                >
                  <TrackingInput
                    prefix={<PrefixIcon type="shop" />}
                    placeholder={companyName}
                    options={{
                      action: 'Enter Company Name',
                      category: 'Contact Us Form | Input',
                    }}
                  >
                    <AntdInput />
                  </TrackingInput>
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.phone}>
          {(phoneNumber: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phoneNumber }}>
              {(fieldRequired) => (
                <Form.Item
                  name="phoneNumber"
                  colon={false}
                  required={false}
                  label={phoneNumber}
                  hasFeedback
                  rules={[{ required: true, message: fieldRequired }]}
                >
                  <TrackingInput
                    prefix={<PrefixIcon type="phone" />}
                    placeholder={phoneNumber}
                    autoComplete="phone"
                    options={{
                      action: 'Enter Phone',
                      category: 'Contact Us Form | Input',
                    }}
                  >
                    <AntdInput />
                  </TrackingInput>
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.fieldEmail}>
          {(fieldEmail) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
              {(fieldRequired) => (
                <Form.Item
                  name="email"
                  colon={false}
                  required={false}
                  label="Email"
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      message: fieldEmail,
                    },
                    { required: true, message: fieldRequired },
                    {
                      validator: checkEmail(),
                    },
                  ]}
                  normalize={(value: string) => value && value.replace(/\s/g, '')}
                >
                  <TrackingInput
                    prefix={<PrefixIcon type="mail" />}
                    placeholder="Email"
                    autoComplete="username"
                    options={{
                      action: 'Enter Email',
                      category: 'Contact Us Form | Input',
                    }}
                  >
                    <AntdInput />
                  </TrackingInput>
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.howDidYouHearUs}>
          {(howDidYouHearUs) => (
            <FormattedMessage {...utilsMessages.acquisitionSourceValidate}>
              {(fieldRequired) => (
                <FormattedMessage {...utilsMessages.acquisitionSourcePlaceHolder}>
                  {(acquisitionSourcePlaceHolder) => (
                    <Form.Item
                      name="acquisitionSource"
                      colon={false}
                      required={false}
                      label={howDidYouHearUs}
                      hasFeedback
                      rules={[{ required: true, message: fieldRequired }]}
                    >
                      <Select
                        items={acquisitionSourceData}
                        placeholder={acquisitionSourcePlaceHolder}
                        onChange={onAcquisitionSourceChange}
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        {isShowAcquisitionText && (
          <FormattedMessage {...utilsMessages.otherAcquisitionSourceValidate}>
            {(fieldRequired) => (
              <Form.Item
                name="acquisitionSourceText"
                colon={false}
                required={false}
                hasFeedback
                rules={[{ required: true, message: fieldRequired }]}
              >
                <AntdInput ref={otherAcquisitionSourceInputRef} placeholder={fieldRequired} />
              </Form.Item>
            )}
          </FormattedMessage>
        )}
        <Form.Item style={{ marginTop: '12px' }}>
          <TrackingButton
            block
            type="primary"
            htmlType="submit"
            trackingCategory="Contact Us Form | Button"
            trackingAction="Submit Contact Us"
          >
            <FormattedMessage {...utilsMessages.send} />
          </TrackingButton>
        </Form.Item>
        <p>
          <FormattedMessage {...messages.agreementPrompt} />{' '}
          <a
            target="_blank"
            href={
              currentLang === 'vi'
                ? `https://kamereo.vn/blog/wp-content/uploads/2024/11/Dieu-khoan-Dich-vu-Dieu-kien-Giao-dich-Chung.pdf`
                : `https://kamereo.vn/blog/wp-content/uploads/2024/11/TERMS-OF-SERVICE-GENERAL-TRANSACTION-CONDITIONS.pdf`
            }
            rel="nofollow"
          >
            <FormattedMessage {...messages.terms} />
          </a>
          {' & '}
          <a
            target="_blank"
            href={
              currentLang === 'vi'
                ? `https://kamereo.vn/blog/wp-content/uploads/2024/11/Chinh-Sach-Bao-Mat.pdf`
                : `https://kamereo.vn/blog/wp-content/uploads/2024/11/Privacy-Policy.pdf`
            }
            rel="nofollow"
          >
            <FormattedMessage {...messages.privacyCondition} />
          </a>
        </p>
        <p>
          <FormattedMessage {...messages.alreadyHasAccount} />{' '}
          <Link to={'/signin'}>
            <FormattedMessage {...utilsMessages.signin} />
          </Link>
        </p>
      </Form>
    </Container>
  );
};

export default ContactUsForm;
